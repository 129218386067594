<template>
  <div>
    
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <h3 class="mb-2 mt-2">Dados pessoais</h3>
            <v-row>
              <!-- COLUNA DE DADOS -->
              <v-col md="12" sm="12" cols="12">
                <v-row>
                  <v-col md="6">
                    <validation-provider v-slot="{ errors }" vid="tipo_pessoa">
                      <v-radio-group
                        v-model="clienteNovo.tipo_pessoa"
                        row
                        hide-details
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.clientes'
                          ) &&
                            !$store.getters['usuario/temPermissao'](
                              'editar.clientes'
                            )
                        "
                        :error-messages="errors"
                        :disabled="$store.state.layout.loading"
                        class="mt-1"
                      >
                        <v-radio label="Física" value="f" hide-details="auto" />
                        <v-radio
                          label="Jurídica"
                          value="j"
                          hide-details="auto"
                        />
                      </v-radio-group>
                    </validation-provider>
                  </v-col>
                  <v-col md="6" sm="12" class="mt-2">
                    <v-switch
                      v-model="clienteNovo.ativo"
                      class="mt-0 pt-0"
                      :readonly="
                        $store.getters['usuario/temPermissao'](
                          'mostrar.clientes'
                        ) &&
                          !$store.getters['usuario/temPermissao'](
                            'editar.clientes'
                          )
                      "
                      :disabled="$store.state.layout.loading"
                      label="Ativo"
                    />
                  </v-col>
                </v-row>
                <!--CPF/CNPJ-->
                <v-row>
                  <v-col md="3" sm="12">
                    <validation-provider
                      v-if="clienteNovo.tipo_pessoa === 'f'"
                      v-slot="{ errors }"
                      vid="cpf_cnpj"
                    >
                      <v-text-field
                        v-model="clienteNovo.cpf_cnpj"
                        v-mask="'###.###.###-##'"
                        outlined
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.clientes'
                          ) &&
                            !$store.getters['usuario/temPermissao'](
                              'editar.clientes'
                            )
                        "
                        dense
                        :disabled="$store.state.layout.loading"
                        hide-details="auto"
                        label="CPF"
                        :error-messages="errors"
                      />
                    </validation-provider>

                    <validation-provider
                      v-if="clienteNovo.tipo_pessoa === 'j'"
                      v-slot="{ errors }"
                      vid="cpf_cnpj"
                    >
                      <v-text-field
                        v-model="clienteNovo.cpf_cnpj"
                        v-mask="'##.###.###/####-##'"
                        outlined
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.clientes'
                          ) &&
                            !$store.getters['usuario/temPermissao'](
                              'editar.clientes'
                            )
                        "
                        :disabled="$store.state.layout.loading"
                        dense
                        hide-details="auto"
                        label="CNPJ"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col md="6" sm="12" cols="12">
                    <validation-provider v-slot="{ errors }" vid="nome">
                      <v-text-field
                        v-model="clienteNovo.nome"
                        outlined
                        dense
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.clientes'
                          ) &&
                            !$store.getters['usuario/temPermissao'](
                              'editar.clientes'
                            )
                        "
                        hide-details="auto"
                        :disabled="$store.state.layout.loading"
                        label="Razão Social"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col md="3" sm="12" cols="12">
                    <validation-provider v-slot="{ errors }" vid="apelido">
                      <v-text-field
                        v-model="clienteNovo.apelido"
                        outlined
                        :readonly="
                          $store.getters['usuario/temPermissao'](
                            'mostrar.clientes'
                          ) &&
                            !$store.getters['usuario/temPermissao'](
                              'editar.clientes'
                            )
                        "
                        dense
                        hide-details="auto"
                        :disabled="$store.state.layout.loading"
                        label="Nome Fantasia"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <h3 class="mb-2 mt-2">Dados para contato</h3>

            <v-row>
              <v-col md="6" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="contato">
                  <v-text-field
                    v-model="clienteNovo.contato"
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    :error-messages="errors"
                    label="Contato"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="telefone">
                  <v-text-field
                    v-model="clienteNovo.telefones[0]"
                    v-mask="'(##)#########'"
                    outlined
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    dense
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Telefone"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="telefone">
                  <v-text-field
                    v-model="clienteNovo.telefones[1]"
                    v-mask="'(##)#########'"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Telefone"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <h3 class="mb-2 mt-2">Dados do endereço</h3>
            <v-row>
              <v-col md="6" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="endereco.logradouro"
                >
                  <v-text-field
                    v-model="clienteNovo.endereco.logradouro"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    required
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Logradouro"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="complemento">
                  <v-text-field
                    v-model="clienteNovo.endereco.complemento"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Complemento"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="bairro">
                  <v-text-field
                    v-model="clienteNovo.endereco.bairro"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Bairro"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="endereco.numero">
                  <v-text-field
                    v-model="clienteNovo.endereco.numero"
                    v-mask="'######'"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Número"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col md="3" cols="12">
                <validation-provider v-slot="{ errors }" vid="endereco.cep">
                  <v-text-field
                    v-model="clienteNovo.endereco.cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="CEP"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" cols="12">
                <autocomplete-estados
                  :readonly="
                    $store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !$store.getters['usuario/temPermissao']('editar.clientes')
                  "
                  v-model="estadoId"
                />
              </v-col>

              <v-col md="3" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="endereco.cidade_id"
                >
                  <autocomplete-cidades
                    v-model="clienteNovo.endereco.cidade_id"
                    :estado-id="estadoId"
                    :readonly="
                      $store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        )
                    "
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.clientes')
              "
              :carregando="carregandoSalvar"
              @click="$emit('salvar', clienteNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
   
    readonly: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      estadoId: null,
      clienteNovo: {
        nome: null,
        apelido: null,
        tipo_pessoa: null,
        cpf_cnpj: null,
        contato: null,
        telefones: [],
        ativo: true,
        endereco: {
          endereco_id: null,
          logradouro: null,
          bairro: null,
          numero: null,
          cep: null,
          cidade_id: null,
        },
      },
    };
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    cliente(cliente) {
      var endereco_id = null;
      if (_.isEmpty(cliente)) return false;
      this.estadoId = 23;
      if (!_.isEmpty(cliente.endereco.cidade)) {
        this.estadoId = cliente.endereco.cidade.estado_id;
        endereco_id = cliente.endereco.id;
      }

      cliente = { ...cliente, ...{ endereco: cliente.endereco } };

      this.clienteNovo = cliente;
      this.clienteNovo.endereco.endereco_id = endereco_id;
    },
  },

  methods: {},
};
</script>
