var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"mt-4"},[_c('v-container',{staticClass:"pt-5"},[_c('h3',{staticClass:"mb-2 mt-2"},[_vm._v("Dados pessoais")]),_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12","cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"vid":"tipo_pessoa"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-radio-group',{staticClass:"mt-1",attrs:{"row":"","hide-details":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.clientes'
                        ) &&
                          !_vm.$store.getters['usuario/temPermissao'](
                            'editar.clientes'
                          ),"error-messages":errors,"disabled":_vm.$store.state.layout.loading},model:{value:(_vm.clienteNovo.tipo_pessoa),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "tipo_pessoa", $$v)},expression:"clienteNovo.tipo_pessoa"}},[_c('v-radio',{attrs:{"label":"Física","value":"f","hide-details":"auto"}}),_c('v-radio',{attrs:{"label":"Jurídica","value":"j","hide-details":"auto"}})],1)]}}])})],1),_c('v-col',{staticClass:"mt-2",attrs:{"md":"6","sm":"12"}},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"readonly":_vm.$store.getters['usuario/temPermissao'](
                        'mostrar.clientes'
                      ) &&
                        !_vm.$store.getters['usuario/temPermissao'](
                          'editar.clientes'
                        ),"disabled":_vm.$store.state.layout.loading,"label":"Ativo"},model:{value:(_vm.clienteNovo.ativo),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "ativo", $$v)},expression:"clienteNovo.ativo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"12"}},[(_vm.clienteNovo.tipo_pessoa === 'f')?_c('validation-provider',{attrs:{"vid":"cpf_cnpj"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.clientes'
                        ) &&
                          !_vm.$store.getters['usuario/temPermissao'](
                            'editar.clientes'
                          ),"dense":"","disabled":_vm.$store.state.layout.loading,"hide-details":"auto","label":"CPF","error-messages":errors},model:{value:(_vm.clienteNovo.cpf_cnpj),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "cpf_cnpj", $$v)},expression:"clienteNovo.cpf_cnpj"}})]}}],null,false,793894481)}):_vm._e(),(_vm.clienteNovo.tipo_pessoa === 'j')?_c('validation-provider',{attrs:{"vid":"cpf_cnpj"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.clientes'
                        ) &&
                          !_vm.$store.getters['usuario/temPermissao'](
                            'editar.clientes'
                          ),"disabled":_vm.$store.state.layout.loading,"dense":"","hide-details":"auto","label":"CNPJ","error-messages":errors},model:{value:(_vm.clienteNovo.cpf_cnpj),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "cpf_cnpj", $$v)},expression:"clienteNovo.cpf_cnpj"}})]}}],null,false,2106536947)}):_vm._e()],1),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"nome"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.clientes'
                        ) &&
                          !_vm.$store.getters['usuario/temPermissao'](
                            'editar.clientes'
                          ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Razão Social","error-messages":errors},model:{value:(_vm.clienteNovo.nome),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "nome", $$v)},expression:"clienteNovo.nome"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"apelido"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                          'mostrar.clientes'
                        ) &&
                          !_vm.$store.getters['usuario/temPermissao'](
                            'editar.clientes'
                          ),"dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Nome Fantasia","error-messages":errors},model:{value:(_vm.clienteNovo.apelido),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "apelido", $$v)},expression:"clienteNovo.apelido"}})]}}])})],1)],1)],1)],1),_c('h3',{staticClass:"mb-2 mt-2"},[_vm._v("Dados para contato")]),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"contato"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"outlined":"","dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"error-messages":errors,"label":"Contato"},model:{value:(_vm.clienteNovo.contato),callback:function ($$v) {_vm.$set(_vm.clienteNovo, "contato", $$v)},expression:"clienteNovo.contato"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"telefone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#########'),expression:"'(##)#########'"}],attrs:{"outlined":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"dense":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Telefone","error-messages":errors},model:{value:(_vm.clienteNovo.telefones[0]),callback:function ($$v) {_vm.$set(_vm.clienteNovo.telefones, 0, $$v)},expression:"clienteNovo.telefones[0]"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"telefone"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##)#########'),expression:"'(##)#########'"}],attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Telefone","error-messages":errors},model:{value:(_vm.clienteNovo.telefones[1]),callback:function ($$v) {_vm.$set(_vm.clienteNovo.telefones, 1, $$v)},expression:"clienteNovo.telefones[1]"}})]}}])})],1)],1),_c('h3',{staticClass:"mb-2 mt-2"},[_vm._v("Dados do endereço")]),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.logradouro"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"required":"","hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Logradouro","error-messages":errors},model:{value:(_vm.clienteNovo.endereco.logradouro),callback:function ($$v) {_vm.$set(_vm.clienteNovo.endereco, "logradouro", $$v)},expression:"clienteNovo.endereco.logradouro"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"complemento"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Complemento","error-messages":errors},model:{value:(_vm.clienteNovo.endereco.complemento),callback:function ($$v) {_vm.$set(_vm.clienteNovo.endereco, "complemento", $$v)},expression:"clienteNovo.endereco.complemento"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"bairro"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Bairro","error-messages":errors},model:{value:(_vm.clienteNovo.endereco.bairro),callback:function ($$v) {_vm.$set(_vm.clienteNovo.endereco, "bairro", $$v)},expression:"clienteNovo.endereco.bairro"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.numero"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"Número","error-messages":errors},model:{value:(_vm.clienteNovo.endereco.numero),callback:function ($$v) {_vm.$set(_vm.clienteNovo.endereco, "numero", $$v)},expression:"clienteNovo.endereco.numero"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.cep"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-###'),expression:"'#####-###'"}],attrs:{"outlined":"","dense":"","readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"hide-details":"auto","disabled":_vm.$store.state.layout.loading,"label":"CEP","error-messages":errors},model:{value:(_vm.clienteNovo.endereco.cep),callback:function ($$v) {_vm.$set(_vm.clienteNovo.endereco, "cep", $$v)},expression:"clienteNovo.endereco.cep"}})]}}])})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('autocomplete-estados',{attrs:{"readonly":_vm.$store.getters['usuario/temPermissao'](
                    'mostrar.clientes'
                  ) &&
                    !_vm.$store.getters['usuario/temPermissao']('editar.clientes')},model:{value:(_vm.estadoId),callback:function ($$v) {_vm.estadoId=$$v},expression:"estadoId"}})],1),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"endereco.cidade_id"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('autocomplete-cidades',{attrs:{"estado-id":_vm.estadoId,"readonly":_vm.$store.getters['usuario/temPermissao'](
                      'mostrar.clientes'
                    ) &&
                      !_vm.$store.getters['usuario/temPermissao'](
                        'editar.clientes'
                      ),"error-messages":errors},model:{value:(_vm.clienteNovo.endereco.cidade_id),callback:function ($$v) {_vm.$set(_vm.clienteNovo.endereco, "cidade_id", $$v)},expression:"clienteNovo.endereco.cidade_id"}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12"}},[_c('btn-salvar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao']('editar.clientes'),"carregando":_vm.carregandoSalvar},on:{"click":function($event){return _vm.$emit('salvar', _vm.clienteNovo)}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }